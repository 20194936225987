import moment from 'moment';

const utils = {
  install(Vue) {
    Vue.prototype.coverte_para_float_valor_input = (value) => {
      let stringValor = value;
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));

      return valorFlutuante;
    }

    Vue.prototype.formatInputFloat = (value) => {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = value.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      return parseFloat(numericValue / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
      }).replace("R$", "").trim();
    }
    Vue.prototype.formatterCurrBR = (value) => {
      if (!parseFloat(value)) {
        return parseFloat(value).toFixed(2).replace(".", ",");
      }

      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      return value.toLocaleString("pt-BR", formatoMoeda).replace("R$","");
    }

    Vue.prototype.formatterFloatBR = (value, fixed=2) => {
      if (!parseFloat(value)) {
        return parseFloat(value).toFixed(fixed).replace(".", ",");
      }
      
      return (value).toFixed(fixed).replace(".", ",");
    }

    Vue.prototype.removeAccent = (value) => {   
      let str = value;
      str = str.replace(/[ÀÁÂÃÄÅ]/g,"A");
      str = str.replace(/[àáâãäå]/g,"a");
      str = str.replace(/[ÈÉÊË]/g,"E");
      return str;
    }

    Vue.prototype.formatDateBR = (value) => {
      return (value) ? moment(value).format('DD/MM/yyyy') : '';
    }

    Vue.prototype.formatDateTimeBR = (value) => {
      return (value) ? moment(value).format('DD/MM/yyyy HH:MM') : '';
    }
  }
}

export default utils